import { darken } from 'polished';
import Color from 'color';

/**
 * Colors
 */
export const BLACK_01 = '#000000';
export const BLACK_02 = '#161E27';
export const BLUE_01 = '#52CAFF';
export const BLUE_02 = '#00A7FF';
export const BLUE_03 = '#0186EB';
export const BLUE_04 = '#027EDD';
export const BLUE_05 = '#0061AA';
export const BLUE_06 = '#7485A9';
export const BLUE_07 = '#445476';
export const BLUE_08 = '#333C4E';
export const BLUE_09 = '#425367';
export const BLUE_10 = '#4ECFFF';
export const BLUE_11 = '#38A9FF';
export const GREEN_01 = '#00C186';
export const GREY_01 = '#E9EEF4';
export const GREY_02 = '#CFD7E0';
export const GREY_03 = '#BCCCDD';
export const GREY_04 = '#A2B5C9';
export const GREY_05 = '#8B9BAD';
export const GREY_06 = '#636F7C';
export const GREY_07 = '#415974';
export const GREY_08 = '#283646';
export const GREY_09 = '#162F4B';
export const GREY_10 = '#748CA7';
export const GREY_11 = '#A5A5A5';
export const GREY_12 = '#9ba8b5';
export const GREY_13 = '#1D1D20';
export const GREY_14 = '#E2E2E2';
export const GREY_15 = '#858585';
export const GREY_16 = '#525252';
export const GREY_17 = '#939396';
export const RED_01 = '#FF2D26';
export const PINK_01 = '#E648E6';
export const WHITE_01 = '#FFFFFF';
export const WHITE_02 = '#F8F8F8';
export const YELLOW_01 = '#FEEA00';
export const WAVE_GREEN = '#A1D141';
export const WAVE_BLUE = '#64BFD7';


/**
 * New Palette
 */
export const COLOR_INFO = '#455DF9';
export const COLOR_SUCCESS = '#17C150';
export const COLOR_WARNING = '#E8D504';
export const COLOR_DANGER = '#FF2D26';
/**
 * Brand Colors
 */
export const COLOR_BEATSOURCE = BLUE_04;
export const COLOR_BEATSOURCE_HOVER = darken(0.1, BLUE_04);
export const COLOR_ACCENT = YELLOW_01;
export const COLOR_ACCENT_HOVER = darken(0.1, YELLOW_01);
export const COLOR_CART = COLOR_BEATSOURCE;
export const COLOR_CART_HOVER = darken(0.1, COLOR_BEATSOURCE);
export const COLOR_WARNING_HOVER = darken(0.1, COLOR_WARNING);
export const COLOR_OPTION = BLACK_02;
export const COLOR_OPTION_HOVER = darken(0.1, BLACK_02);
export const COLOR_DEFAULT = WHITE_01;
export const COLOR_DEFAULT_HOVER = darken(0.1, WHITE_01);
export const COLOR_BUY = BLUE_06;
export const COLOR_BUY_HOVER = BLUE_04;
export const COLOR_OPTION_VARIANT = BLUE_09;
export const COLOR_OPTION_VARIANT_HOVER = darken(0.1, BLUE_09);
export const COLOR_BEATSOURCE_NAV = BLACK_01;
export const COLOR_NAV_DEFAULT = BLUE_04;
export const COLOR_NAV_HOVER = BLUE_05;
export const COLOR_NAV_ACTIVE = WHITE_01;


/**
 * Color - Player
 */
export const COLOR_PLAYER_BG = BLACK_02;


/**
 * Color - Waveform
 */
export const COLOR_WAVEFORM = Color(COLOR_ACCENT);
export const COLOR_WAVE_AVAILABLE = Color('#cccccc').darken(0.5);
export const COLOR_TRACK_PLAYED = COLOR_WAVEFORM;
export const COLOR_TRACK_UNPLAYED = COLOR_WAVEFORM.darken(0.4);
export const COLOR_TRACK_AVAILABLE = COLOR_WAVE_AVAILABLE; // Default
export const COLOR_TRACK_AVAILABLE_ND_ENABLED = COLOR_WAVEFORM.darken(0.7); // Needledrop Enabled
export const COLOR_TRACK_AVAILABLE_BG = Color(COLOR_PLAYER_BG);
export const COLOR_MARKER = Color(WHITE_01);
