import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import App from '@components/app/App.btsrc'
import config from '@beatport/ui/dist/cjs/config';
import ErrorBoundary from '@components/error/ErrorBoundary';
import useAuth from 'hooks/useAuth';
import AuthContext from 'context/AuthContext';
import BeatsourceLogo from '@components/icons/BeatsourceLogo';

import '@integrations/Sentry';
import './main.btsrc.scss';
import ErrorComponent from '@components/error/ErrorComponent';
import Loading from '@components/loading/Loading';

const Anchor = ({ href, ...extra }) => {
  return (<a href={`https://www.beatsource.com${href}`} target="_blank" rel="noreferrer" {...extra} />)
}

config.useCustomAnchors = true;
config.Anchor = Anchor;

const container = document.createElement('div');
container.id = 'beatport-embed';
document.body.appendChild(container);

const Main : React.FC = () => {
  const { auth, loading, error } = useAuth();

  if (loading) {
    return (
      <Loading />
    )
  }

  if (error) {
    return (
      <ErrorComponent LogoComponent={BeatsourceLogo} title="Sorry, something went wrong.">
        <p>We&apos;ve been notified and we&apos;re been working on it.</p>
      </ErrorComponent>
    )
  }

  return (
    <ErrorBoundary LogoComponent={BeatsourceLogo}>
      <Router>
        <AuthContext.Provider value={auth}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </AuthContext.Provider>
      </Router>
    </ErrorBoundary>
  )
}

ReactDOM.render(<Main />, container);
